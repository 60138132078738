
.footer-container {
    padding: 32px 12px 0
    border-top: 1px solid rgb(208, 208, 208)

    @media screen and (min-width: 1024px) {
        padding: 64px 50px 0;
        margin-top: 24px
    }
}

.the-main-footer-content {
    display: flex;
    flex-direction: column;

    font-size: 14px;

    @media screen and (min-width: 1024px) {
        // padding: 32px 0 16px 0;
        flex-direction: row;
    }

    > .column:not(:last-child) {
        display: none
        @media screen and (min-width: 1024px) {
            width: 33.33%;
            display: block;
        }
    }

    > .studio7-info {
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;
        }

        .site-logo {
            height: $site-logo-height-desktop;

            > .img {
                height: 100%;
            }
        }

        > .label {
            font-weight: 500;
            line-height: 1.4;
            color: $color-theme-white-75;

            @media screen and (min-width: 1024px) {
                margin-top: 16px;
            }
        }
    }

    .title-group {
        margin-bottom: 30px;

        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;

        @media screen and (min-width: 1024px) {
            font-family: "SF Pro Display";
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.0225rem;
            line-height: 1.6875rem !important;
        }
    }

    .menu-list {
        display: flex;
        flex-direction: column;
    }

    .menu {
        margin-bottom: 16px;

        font-size: 12px;
        font-weight: 600;
        line-height: 1.6;

        @media screen and (min-width: 1024px) {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 16px;
            padding-right: 15px;
        }

        > .link {
            color: black
            cursor: pointer;
            font-size: 0.875rem;
            line-height: 1.3125rem;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.6px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                text-underline-offset: 0.1875rem;
            }
        }
    }

    .apple-premium-partner {
        padding: 56px 0
        display: flex;
        flex-direction: column;
        margin: 0 30%

        @media screen and (min-width: 1024px) {
            padding: 0;
            margin: 0;
        }
    }

    .partner-logo {
        margin-bottom: 1rem;
        width: 100px;
        height: auto;
        object-fit: contain;
    }

    .authorized-service-provider,
    .authorized-education-specialist {
        margin-bottom: 1rem;
        width: 130px;
        height: auto;
        object-fit: contain;
    }
}

.the-main-footer-contact {
    padding: 0

    @media screen and (min-width: 1024px) {
        padding: 64px 0
    }

    .footer-social-group {
        line-height: 1.15;
        font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: 16px;
        text-size-adjust: 100%;
        font-weight: 500;
        color: rgb(0, 0, 0);
        box-sizing: border-box;
        overflow-wrap: break-word;
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 1.5rem;
        margin-bottom: 24px

        @media screen and (min-width: 1024px) {
            justify-content: flex-end;
        }

        > a {
            justify-content: center;
            align-items: center;
            display: flex;
            width: 20px;
            height: 20px;

            > img {
                object-fit: cover;
                object-position: center center;
                max-width: 18px;
                max-height: 18px;
            }

            @media screen and (min-width: 1024px) {
              //
            }
        }
    }

    .footer-payment-group {
        display: flex;
        justify-content: center;
        line-height: 1.15;
        margin: 15px 0px;
        padding-top: 17px;

        @media screen and (min-width: 1024px) {
            justify-content: flex-end;
            margin: 0.9375rem 0px;
            padding-top: 2.25rem;
        }

        > img {
            width auto
            height: 19px;
            line-height: 1.15;
            font-size: 16px;

            @media screen and (min-width: 1024px) {
                height: 24px;
            }
        }
    }

    .footer-copy-right {
        line-height: 1.15;
        font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        text-size-adjust: 100%;
        color: rgba(18, 18, 18, 0.75);
        font-size: 0.6875rem;
        font-weight: 400;
        letter-spacing: 0.7px;
        text-align: center;

        @media screen and (min-width: 1024px) {
            text-align: right;
        }
    }

    .divider {
        margin: 2rem 0;
        border-top: 1px solid #d0d0d0;
        border-color: #7d7d7d;

        @media screen and (min-width: 1024px) {
            display: none;
        }
    }
}

.is-first {
    border-top: 0.5px solid #7d7d7d;
}
